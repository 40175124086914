<template>
  <div id="app">
    <div class="app">
      <img 
        class="app__logo"
        src="@/assets/gb.svg"
      />
      <div class="app__text">
        <h4>Please pardon our dust!</h4>
        <p>We're working on some really awesome educational software.</p>
        <p>You can follow us on social media in the meantime:</p>
      </div>
      <div class="app__social">
        <a href="https://twitter.com/grvlbox">
          <img class="app__icon" src="@/assets/twitter.svg" />
        </a>
        <a href="https://www.instagram.com/gravelbox/">
          <img class="app__icon" src="@/assets/instagram.svg" />
        </a>
        <a href="https://www.facebook.com/GravelBox-Inc-100396659232408">
          <img class="app__icon" src="@/assets/facebook.svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gb-underconst'
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500&display=swap');

body {
  background: #252627;
  font-family: 'Inconsolata', monospace;
}

@media screen and (max-height: 575px) {
  .app {
    transform: translateX(-50%) !important;
    top: 0% !important;
  }
}

.app {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%) translateY(-50%);

  &__logo {
    max-width: 350px;
  }

  &__text {
    text-align: center;
    font-weight: 500;
    & h4 {
      font-size: 1.5rem;
      margin: 0;
      margin-top: 10px;
    }
    & p {
      margin-top: 15px;
      font-size: 1rem;
    }
  }

  &__icon {
    width: 40px;
    margin: 0 10px 0 10px;
  }
}

</style>
